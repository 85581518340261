'use client';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@constants/routes.constants';
import { useGetUserQuery } from '@lib/redux/api/user-api';
import { useEffect } from 'react';

export default function RootPage() {
  const { data, isSuccess, isLoading } = useGetUserQuery();

  const router = useRouter();

  useEffect(() => {
    router.prefetch(ROUTES.dashboard.root);
    router.prefetch(ROUTES.auth);
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isSuccess && data) {
      router.push(ROUTES.dashboard.root);
    } else {
      router.push(ROUTES.auth);
    }
  }, [isSuccess, isLoading]);

  return null;
}
